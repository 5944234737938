'use client'

import { CountryCode } from '@shopify/hydrogen-react/storefront-api-types'
import Cookies from 'js-cookie'
import { proxy } from 'valtio'

import { SessionType } from 'core/types/session'
import { getChannel, isB2BChannel } from 'core/utils/channel'
import isLocalStorageSupported from 'core/utils/isLocalStorageSupported'
import { consent } from 'core/utils/tracking'

const storage: { [k: string]: Record<string, any> } = {}

export default proxy({
  get channel() {
    return this.catalog || getChannel(this.shippingCountry)
  },

  set options(value: Record<string, string>) {
    isLocalStorageSupported
      ? window.localStorage.setItem(
          SessionType.StorageKeys.Options,
          JSON.stringify({
            ...this.options,
            ...value,
          })
        )
      : (storage[SessionType.StorageKeys.Options] = {
          ...this.options,
          ...value,
        })
  },
  get options() {
    return isLocalStorageSupported
      ? JSON.parse(window.localStorage.getItem(SessionType.StorageKeys.Options) || '{}')
      : storage[SessionType.StorageKeys.Options] || {}
  },

  set sizeChartUnit(value: string) {
    this.options = {
      [SessionType.OptionKeys.SizeChartUnit]: value,
    }
  },
  get sizeChartUnit() {
    return this.options[SessionType.OptionKeys.SizeChartUnit] || ''
  },

  set navigationGender(value: string) {
    this.options = {
      [SessionType.OptionKeys.NavigationGender]: value,
    }
  },
  get navigationGender() {
    return this.options[SessionType.OptionKeys.NavigationGender] || '/men'
  },

  set buildHash(value: string) {
    this.options = {
      [SessionType.OptionKeys.BuildHash]: value,
    }
  },
  get buildHash() {
    return this.options[SessionType.OptionKeys.BuildHash] || ''
  },

  set email(value: string) {
    this.options = {
      [SessionType.OptionKeys.Email]: value,
    }
  },
  get email() {
    return this.options[SessionType.OptionKeys.Email] || ''
  },

  set shippingCountry(value: CountryCode) {
    if (value) {
      Cookies.set('shipping_country', value, { expires: 7, path: '/' })
    }
  },
  get shippingCountry() {
    return (Cookies.get('shipping_country') as CountryCode) || 'NL'
  },

  set catalog(value: string) {
    if (value) {
      Cookies.set(SessionType.StorageKeys.Catalog, value, { expires: 7, path: '/' })
    } else {
      Cookies.remove(SessionType.StorageKeys.Catalog, { expires: 7, path: '/' })
    }
  },
  get catalog() {
    return Cookies.get(SessionType.StorageKeys.Catalog) as string
  },

  set location(value: string) {
    if (value) {
      Cookies.set(SessionType.StorageKeys.Location, value, { expires: 7, path: '/' })
    } else {
      Cookies.remove(SessionType.StorageKeys.Location, { expires: 7, path: '/' })
    }
  },
  get location() {
    return Cookies.get(SessionType.StorageKeys.Location) as string
  },

  set customerToken(value: string) {
    if (value) {
      Cookies.set(SessionType.StorageKeys.CustomerToken, value, { expires: 7, path: '/' })
    } else {
      Cookies.remove(SessionType.StorageKeys.CustomerToken, { expires: 7, path: '/' })
    }
  },
  get customerToken() {
    return Cookies.get(SessionType.StorageKeys.CustomerToken) as string
  },

  set storefrontToken(value: string) {
    if (value) {
      Cookies.set(SessionType.StorageKeys.StorefrontToken, value, { expires: 7, path: '/' })
    } else {
      Cookies.remove(SessionType.StorageKeys.StorefrontToken, { expires: 7, path: '/' })
    }
  },
  get storefrontToken() {
    return Cookies.get(SessionType.StorageKeys.StorefrontToken) as string
  },

  set algoliaUserToken(value: string) {
    Cookies.set(SessionType.StorageKeys.AlgoliaUserToken, value, { expires: 7, path: '/' })
  },
  get algoliaUserToken() {
    return Cookies.get(SessionType.StorageKeys.AlgoliaUserToken) || ''
  },

  set gdprConsent(value: boolean) {
    Cookies.set(SessionType.StorageKeys.Gdpr, value ? '1' : '0', { expires: 200, path: '/' })
  },
  get gdprConsent() {
    const consentValue = !!Cookies.get(SessionType.StorageKeys.Gdpr) || false

    // TODO: remove when cookie banner overhaul is finished
    // if (consentValue) {
    consent([
      'ad_storage',
      'ad_user_data',
      'ad_personalization',
      'analytics_storage',
      'security_storage',
      'functionality_storage',
      'personalization_storage',
    ])
    // }

    return consentValue
  },

  set subscribed(value: boolean) {
    Cookies.set(SessionType.StorageKeys.Subscribed, value ? '1' : '0', { expires: 200, path: '/' })
  },
  get subscribed() {
    return !!Cookies.get(SessionType.StorageKeys.Subscribed) || false
  },

  set channelIds(value: Record<string, string>) {
    Cookies.set(
      SessionType.StorageKeys.ChannelIds,
      JSON.stringify({
        ...this.channelIds,
        ...value,
      }),
      { expires: 7, path: '/' }
    )
  },
  get channelIds() {
    return JSON.parse(Cookies.get(SessionType.StorageKeys.ChannelIds) || '{}')
  },

  set cartId(value: string) {
    const key = isB2BChannel(this.channel) ? this.channel : 'd2c'
    this.channelIds = {
      [key]: value,
    }
  },
  get cartId() {
    const key = isB2BChannel(this.channel) ? this.channel : 'd2c'
    return this.channelIds[key] || ''
  },

  get orderId() {
    return Cookies.get(SessionType.StorageKeys.OrderId)
  },
}) as SessionType.Session
