import { CountryCode } from '@shopify/hydrogen-react/storefront-api-types'

import { Channel, ChannelType } from 'core/channel'

export function getPricingFromCountry<T extends Record<Algolia.PricingKey, Algolia.Pricing>>(
  product: T,
  country: CountryCode
): T[Algolia.PricingKey] {
  switch (country) {
    case 'US':
      return product.pricingDlr
    case 'GB':
      return product.pricingGbp
    case 'CH':
      return product.pricingChf
    default:
      return product.pricingEur
  }
}

export function getPricingFromChannel<T extends Record<Algolia.PricingKey, any>>(
  product: T,
  channel: ChannelType
): T[Algolia.PricingKey] {
  switch (channel) {
    case Channel.Usd:
      return product.pricingDlr
    case Channel.Gbp:
      return product.pricingGbp
    case Channel.Chf:
      return product.pricingChf
    case Channel.Eur:
      return product.pricingEur
    default:
      return (product as Algolia.Product)?.pricing
  }
}

export function getChannel(country: CountryCode): Channel {
  switch (country) {
    case 'US':
      return Channel.Usd
    case 'GB':
      return Channel.Gbp
    case 'CH':
      return Channel.Chf
    default:
      return Channel.Eur
  }
}

export function isB2BChannel(channel: ChannelType): boolean {
  return ![Channel.Chf, Channel.Gbp, Channel.Usd, Channel.Eur].includes(channel as Channel)
}

export function getChannels(): Channel[] {
  return [Channel.Eur, Channel.Gbp, Channel.Chf, Channel.Usd]
}
