import { makeOperation } from '@urql/core'
import { makeSubject, pipe, merge, filter, fromValue, debounce, mergeMap, takeUntil } from 'wonka'

import type { ShopifyExecutionResult } from './types'
import type { Exchange, Operation, CombinedError, OperationResult } from '@urql/core'

interface RetryExchangeOptions {
  retryIf?(error: CombinedError, operation: Operation): boolean
  retryDelay(res: OperationResult): number
}

interface RetryState {
  delay: number
}

export default function retryExchange(options: RetryExchangeOptions): Exchange {
  const { retryIf, retryDelay } = options

  return ({ forward }) =>
    (operations$) => {
      const { source: retry$, next: nextRetryOperation } = makeSubject<Operation>()

      const retryWithBackoff$ = pipe(
        retry$,
        mergeMap((operation: Operation) => {
          const retry: RetryState = operation.context.retry || {
            delay: 0,
          }

          const teardown$ = pipe(
            operations$,
            filter((op) => {
              return (op.kind === 'query' || op.kind === 'teardown') && op.key === operation.key
            })
          )

          return pipe(
            fromValue(
              makeOperation(operation.kind, operation, {
                ...operation.context,
                retry,
              })
            ),
            debounce(() => retry.delay),
            takeUntil(teardown$)
          )
        })
      )

      return pipe(
        merge([operations$, retryWithBackoff$]),
        forward,
        filter((res) => {
          const operation = res.operation
          const retry = res.operation.context.retry as RetryState | undefined

          if (res.operation.context.cost) {
            const shopifyExtension = res.extensions as ShopifyExecutionResult['extensions']
            const operationDefinition = res.operation.query.definitions.find(
              ({ kind }) => kind === 'OperationDefinition'
            ) as { name: { value: string } } | null

            if (operationDefinition) {
              console.info('[GRAPHQL]', operationDefinition.name.value, shopifyExtension?.cost.actualQueryCost)
            }
          }

          if (!res.error || (retryIf ? !retryIf(res.error, res.operation) : !res.error.networkError)) {
            // Reset the delay state for a successful operation
            if (retry) {
              retry.delay = 0
            }
            return true
          }

          if (!operation) return true

          const delay = retryDelay(res)

          operation.context.retry = {
            ...retry,
            delay,
          } as RetryState

          nextRetryOperation(operation)
          return false
        })
      )
    }
}
