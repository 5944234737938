function isSupported(): boolean {
  try {
    if (typeof window['localStorage'] !== 'undefined') {
      const key = 'storage-check'
      window.localStorage.setItem(key, key)
      window.localStorage.removeItem(key)
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export default isSupported()
