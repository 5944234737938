const currencySymbols = {
  USD: '$',
  EUR: '€',
  GBP: '£',
} as const
type currencyType = keyof typeof currencySymbols

export const getCurrencySymbol = (currencyCode: string): string => {
  return currencySymbols[currencyCode as currencyType] || currencyCode
}

export function getPriceWithSymbol(price: number, currency: string, multiplier = 1, isDiscount = false): string {
  return `${getCurrencySymbol(currency)}${isDiscount ? '-' : ''}${(price * multiplier).toFixed(2)}`
}
