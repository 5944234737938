const COLLECTIONS = {
  accessories: 'Q29sbGVjdGlvbjoxMDY=',
  activewear: 'Q29sbGVjdGlvbjo5Nw==',
  bralettes: 'Q29sbGVjdGlvbjoyMw==',
  bikinis: 'Q29sbGVjdGlvbjo4OQ==',
  bottoms: 'Q29sbGVjdGlvbjoxMDk=',
  tops: 'Q29sbGVjdGlvbjoxMDg=',
  briefs: 'Q29sbGVjdGlvbjoyNQ==',
  boxerBriefs: 'Q29sbGVjdGlvbjo4',
  boxerShorts: 'Q29sbGVjdGlvbjo5',
  caps: 'Q29sbGVjdGlvbjozOA==',
  classics: 'Q29sbGVjdGlvbjo5OQ==',
  crewSocks: 'Q29sbGVjdGlvbjo0MQ==',
  linerSocks: 'Q29sbGVjdGlvbjoxMTM=',
  casualSocks: 'Q29sbGVjdGlvbjoxMTI=',
  embroideries: 'Q29sbGVjdGlvbjoxMDA=',
  giftboxes: 'Q29sbGVjdGlvbjoxMw==',
  hoodies: 'Q29sbGVjdGlvbjo0Mw==',
  loungewear: 'Q29sbGVjdGlvbjo5NA==',
  men: 'Q29sbGVjdGlvbjo5MQ==',
  outlet: 'Q29sbGVjdGlvbjoxMDc=',
  prints: 'Q29sbGVjdGlvbjo4OA==',
  scrunchies: 'Q29sbGVjdGlvbjo0Ng==',
  socks: 'Q29sbGVjdGlvbjo0Mg==',
  masks: 'Q29sbGVjdGlvbjoyNw==',
  sweats: 'Q29sbGVjdGlvbjo1Mw==',
  sweaters: 'Q29sbGVjdGlvbjoxMDI=',
  pants: 'Q29sbGVjdGlvbjoxMDM=',
  sweatpants: 'Q29sbGVjdGlvbjo0NA==',
  swimshorts: 'Q29sbGVjdGlvbjoxMDQ=',
  leggings: 'Q29sbGVjdGlvbjoxMDU=',
  swimwear: 'Q29sbGVjdGlvbjoxMA==',
  tShirts: 'Q29sbGVjdGlvbjoxMTA=',
  undershirts: 'Q29sbGVjdGlvbjozNw==',
  underwear: 'Q29sbGVjdGlvbjo5Mw==',
  women: 'Q29sbGVjdGlvbjo5Mg==',
  kids: 'Q29sbGVjdGlvbjoxMDE=',
}

export const styleCollections = [COLLECTIONS.classics, COLLECTIONS.prints, COLLECTIONS.embroideries]

export const styleSlugCollections = ['classics', 'prints', 'embroideries']

export const genderCollections = [COLLECTIONS.men, COLLECTIONS.women, COLLECTIONS.kids]
export const genderSlugCollections = ['men', 'women', 'kids']

export default COLLECTIONS
