import { Client, fetchExchange } from '@urql/core'

import calculateDelayCost from './calculateDelayCost'
import retryExchange from './retryExchange'

import type { ShopifyExecutionResult } from './types'
import type { GraphQLError } from 'graphql'

function isThrottledError(error: GraphQLError): boolean {
  return error.extensions?.code === 'THROTTLED'
}

export default function getClient(url: string, headers: Record<string, string>): typeof client {
  const client = new Client({
    url,
    exchanges: [
      retryExchange({
        retryIf: (error) => {
          return error.graphQLErrors.map((err) => isThrottledError(err)).some(Boolean)
        },
        retryDelay: (res) => {
          const shopifyExtension = res.extensions as ShopifyExecutionResult['extensions']
          return shopifyExtension?.cost ? calculateDelayCost(shopifyExtension.cost) : 0
        },
      }),
      fetchExchange,
    ],
    fetchOptions: () => ({
      headers,
    }),
  })

  return client
}
