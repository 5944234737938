import { CountryCode } from '@shopify/hydrogen-react/storefront-api-types'

import { Channel } from 'core/channel'

export namespace SessionType {
  export enum StorageKeys {
    AuthTokenId = '_shopify_auth_token_id',
    AuthRefreshToken = '_shopify_auth_refresh_token',
    AuthToken = '_shopify_auth_token',

    CustomerToken = 'customer_token',
    StorefrontToken = 'storefront_token',

    ShippingCountry = 'shipping_country',
    Catalog = 'catalog',
    Location = 'location',
    ChannelIds = 'channel_ids',
    OrderId = 'order_id',
    AlgoliaUserToken = '_ALGOLIA',
    Gdpr = 'gdpr_consent',
    Subscribed = 'subscribed',
    Options = 'options',
  }

  export enum OptionKeys {
    SizeChartUnit = 'sizeChartUnit',
    NavigationGender = 'navGender',
    BuildHash = 'buildHash',
    Email = 'email',
    Grid = 'grid',
  }

  export type Session = {
    channel: Channel
    cartId: string
    catalog: string
    location?: string
    orderId: string
    channelIds: Record<string, string>
    storefrontToken?: string
    customerToken?: string
    shippingCountry: CountryCode
    options: Record<string, string>
    sizeChartUnit: string
    navigationGender: string
    buildHash: string
    email: string
    algoliaUserToken: string
    gdprConsent: boolean
    subscribed: boolean
  }
}
