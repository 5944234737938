import type { CostExtension } from './types'

export default function calculateDelayCost(cost: CostExtension): number {
  const {
    requestedQueryCost,
    actualQueryCost,
    throttleStatus: { currentlyAvailable, restoreRate },
  } = cost

  const requested = actualQueryCost || requestedQueryCost
  const restoreAmount = Math.max(0, requested - currentlyAvailable)

  return Math.ceil(restoreAmount / restoreRate) * 1000
}
