import mergeClasses from 'core/utils/mergeClasses'
import { getPriceWithSymbol } from 'core/utils/price'

export default function Price({
  price,
  currencyCode,
  undiscountedPrice,
  multiplier,
  isDiscount,
  center,
  large,
  className,
}: {
  price?: number
  currencyCode: string
  undiscountedPrice?: number | null
  multiplier?: number
  isDiscount?: boolean
  center?: boolean
  large?: boolean
  className?: string
}): JSX.Element | null {
  if (typeof price === 'undefined' || price === null) {
    return null
  }

  const subDivClass = mergeClasses('leading-none', center ? 'text-center' : 'text-left')
  const isSale = !!undiscountedPrice || !!isDiscount

  return (
    <div
      className={mergeClasses(
        'leading-none flex flex-row font-normal text-ink ',
        large ? 'text-lg' : 'text-md',
        className
      )}
    >
      {!!undiscountedPrice && (
        <div className={mergeClasses(subDivClass, isSale && 'line-through text-disabled mr-xs')}>
          {getPriceWithSymbol(undiscountedPrice, currencyCode, multiplier)}
        </div>
      )}
      <div className={subDivClass}>{getPriceWithSymbol(price, currencyCode, multiplier, isDiscount)}</div>
    </div>
  )
}
