import { styleSlugCollections } from 'core/config/collections'

type TaxedMoney = {
  displayGrossPrices?: boolean
  gross?: {
    amount?: number | null
    currency?: string | null
  } | null
  net?: {
    amount?: number | null
  } | null
  tax?: {
    amount?: number | null
  } | null
}

type TaxedMoneyRange = {
  stop?: TaxedMoney | null
}

export function getVariantFromTitle<Tobj, T extends Tobj & { title: string }>(variants: T[], title: string): T | null {
  return variants?.find((variant) => variant.title === title) || null
}

export function getPriceActionFromPricing<
  Tobj,
  T extends Tobj & {
    priceRange?: TaxedMoneyRange | null
    priceRangeUndiscounted?: TaxedMoneyRange | null
    price?: TaxedMoney | null
    priceUndiscounted?: TaxedMoney | null
    onSale?: boolean | null
  },
>(
  pricing?: T | null,
  displayGrossPrices?: boolean
): {
  undiscountedPrice: number | null
  price: number
  currencyCode: string
  tax: number
} {
  const displayGross = displayGrossPrices || !!pricing?.priceRange?.stop?.displayGrossPrices

  const undiscountedRange = displayGross
    ? pricing?.priceRangeUndiscounted?.stop?.gross
    : pricing?.priceRangeUndiscounted?.stop?.net
  const undiscounted = displayGross ? pricing?.priceUndiscounted?.gross : pricing?.priceUndiscounted?.net

  const pricingRange = displayGross ? pricing?.priceRange?.stop?.gross : pricing?.priceRange?.stop?.net
  const pricingMoney = displayGross ? pricing?.price?.gross : pricing?.price?.net
  const tax = pricing?.priceRange?.stop?.tax?.amount || pricing?.price?.tax?.amount || 0

  const undiscountedPrice = undiscountedRange?.amount || undiscounted?.amount || 0
  const price = pricingRange?.amount || pricingMoney?.amount || 0
  const isOnSale = pricing?.onSale
  const currencyCode = pricing?.priceRange?.stop?.gross?.currency || pricing?.price?.gross?.currency || 'EUR'

  if (isOnSale && undiscountedPrice > price) {
    return { undiscountedPrice, price, currencyCode, tax }
  }

  const max = Math.max(undiscountedPrice, price)

  return { price: max, undiscountedPrice: null, currencyCode, tax }
}

export function getLinkFromProduct<
  Tobj,
  T extends Tobj & {
    handle: string
    collections: {
      nodes?:
        | {
            id: string
            handle: string
            isCategory: {
              value: string
            } | null
          }[]
        | null
    }
  },
>(product: T, host = 'https://a-dam.com'): string {
  const gender = product.collections.nodes?.find(({ handle }) => ['men', 'women', 'kids'].includes(handle))?.handle
  const category = product.collections.nodes?.find(({ isCategory }) => isCategory)

  return `${host}/${gender}/${category?.handle}/${product.handle}`
}

export function sortProductsOnType<
  Tobj,
  T extends Tobj & {
    title: string
    isBundle: boolean
    style?: string | null
  },
>(products: T[]): T[] {
  const sortedPacks = [...products].sort((a, b) => {
    if (!a.isBundle && !b.isBundle) return 0

    const numberA = parseInt(a.title.charAt(0), 10)
    const numberB = parseInt(b.title.charAt(0), 10)

    if (numberA < numberB) return -1
    if (numberA > numberB) return 1
    return 0
  })

  const sortedByKind = [...sortedPacks].sort((a, b) => {
    const aIndex = a.isBundle ? 1 : 0
    const bIndex = b.isBundle ? 1 : 0

    if (aIndex < bIndex) return -1
    if (aIndex > bIndex) return 1
    return 0
  })

  const sortedByStyle = [...sortedByKind].sort((a, b) => {
    const aIndex = styleSlugCollections.indexOf(a?.style || '')
    const bIndex = styleSlugCollections.indexOf(b?.style || '')

    if (aIndex < bIndex) return -1
    if (aIndex > bIndex) return 1
    return 0
  })

  return sortedByStyle
}
